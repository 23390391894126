import {
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';

import type { IconType } from 'react-icons';

import { DisconnectedIcon } from '../../shared/icons/DisconnectedIcon';
import { ChevronDownIcon } from '../../shared/icons/ChevronDownIcon';
import { ConnectionAccountDetail } from '../types';
import { DisconnectConfirmationModal } from './DisconnectConfirmationModal';
import { LinkIcon } from 'shared/icons/Link';
import { useConnectSession } from 'connections-v2/hooks/useConnectSession';
import { ModalNewConnectSession } from 'connections-v2/components/ConnectSession/ModalNewConnectSession';
import { useApplication } from 'applications/hooks';
import { FeatureFlag } from 'constant/feature-flags';
import { useFlag } from '@unleash/proxy-client-react';

const ConnectionMenuItem = ({
  icon,
  text,
  color = COLORS.FINCH.GRAY,
  hoverColor = COLORS.FINCH.BLACK,
  onClick,
}: {
  icon: IconType;
  text: string;
  color?: string;
  hoverColor?: string;
  onClick?: () => void;
}) => {
  return (
    <MenuItem
      _hover={{
        backgroundColor: hoverColor,
        borderRadius: '4px',
        color,
      }}
      onClick={onClick}
    >
      <Flex gap={1} color={color}>
        <HStack spacing="8px">
          <Icon as={icon} color={color} />
          <Text fontSize={'16px'}>{text}</Text>
        </HStack>
      </Flex>
    </MenuItem>
  );
};
export const ConnectionDetailActionMenu = ({
  connectionDetail,
}: {
  connectionDetail: ConnectionAccountDetail | null;
}) => {
  const {
    isOpen: isDisconnectModalOpen,
    onOpen: onDisconnectModalOpen,
    onClose: onDisconnectModalClose,
  } = useDisclosure();

  const { application } = useApplication();
  const isFlatfileEnabled = application?.features.flatFileEnabled;
  const isConnectionDisconnectEnabled = useFlag(
    FeatureFlag.DashboardConnectionDisconnect,
  );

  const {
    isOpen: isNewConnectSessionOpen,
    onOpen: onNewConnectSessionOpen,
    onClose: onNewConnectSessionClose,
  } = useDisclosure();

  const {
    create: { mutate, data, isPending },
  } = useConnectSession();

  const onConnectionDisconnectRequested = () => {
    onDisconnectModalOpen();
  };

  const onCreateNewConnectSession = () => {
    if (connectionDetail) {
      mutate(
        {
          connectionId: connectionDetail.connectionAccountId,
          providerId: connectionDetail.providerId,
          externalCompanyId: null,
          externalCompanyName: null,
        },
        { onSuccess: onNewConnectSessionOpen },
      );
    }
  };

  return (
    <>
      {connectionDetail && (
        <DisconnectConfirmationModal
          isOpen={isDisconnectModalOpen}
          onClose={onDisconnectModalClose}
          connectionId={connectionDetail.connectionAccountId}
          companyName={connectionDetail.companyName}
          clientId={connectionDetail.clientId}
          onConnectionDisconnected={() => {}}
        />
      )}
      {/* One of features have to be enabled to show the menu */}
      {(isConnectionDisconnectEnabled || isFlatfileEnabled) && (
        <Menu placement="bottom-end">
          <MenuButton
            as={Button}
            aria-label="Actions"
            rightIcon={<Icon as={ChevronDownIcon} color="white"></Icon>}
            size="md"
            variant="primaryPurple"
            isLoading={isPending}
          >
            Actions
          </MenuButton>
          <MenuList minWidth="200px" borderRadius={'8px'}>
            {isFlatfileEnabled && (
              <ConnectionMenuItem
                icon={LinkIcon}
                hoverColor={COLORS.GRAY.GRAY_200}
                text="Create a New Connect Link"
                onClick={onCreateNewConnectSession}
              />
            )}
            {isConnectionDisconnectEnabled && (
              <ConnectionMenuItem
                icon={DisconnectedIcon}
                text="Disconnect"
                color={COLORS.FINCH.RED}
                hoverColor={COLORS.LIGHT_RED}
                onClick={onConnectionDisconnectRequested}
              />
            )}
          </MenuList>
        </Menu>
      )}
      {data?.connectUrl && (
        <ModalNewConnectSession
          isOpen={isNewConnectSessionOpen}
          onClose={onNewConnectSessionClose}
          connectUrl={data.connectUrl}
          helperText="This URL is single-use and expires after 14 days."
        />
      )}
    </>
  );
};
